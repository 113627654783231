<template>
  <div>
    <div ref="form" :style="{ height: trHeight['leaves'] || 'auto' }" class="repeater-form">
      <div class="d-flex">
        <feather-icon icon="SkipForwardIcon" size="19" />
        <h4 class="mb-0">Leaves of Absence</h4>
      </div>

      <b-form>
        <!-- Row Loop -->
        <b-row v-for="(item, index) in itemsLeave" :id="item.id" :key="item.id" ref="row">
          <!-- Leave Start -->
          <b-col md="3">
            <b-form-group label="Start" label-for="leave-start">
              <b-form-datepicker disabled id="leave-start" v-model="item.start" class="mb-1" />
            </b-form-group>
          </b-col>

          <!-- Leave End -->
          <b-col md="3">
            <b-form-group label="End" label-for="leave-end">
              <b-form-datepicker disabled id="leave-end" v-model="item.end" class="mb-1" />
            </b-form-group>
          </b-col>

          <!-- Leave Cohort -->
          <b-col md="6">
            <b-form-group label="Cohort" label-for="leave-cohort">
              <b-form-input disabled id="leave-cohort" type="text" :value="item.cohort ? item.cohort.name || item.cohort : 'unknown'" />
            </b-form-group>
          </b-col>

          <!-- Leave Reason -->
          <b-col md="10">
            <b-form-group label="Reason" label-for="leave-reason">
              <b-form-input id="leave-reason" type="text" v-model="item.reason" />
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col lg="2" md="2" class="mb-50">
            <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="success" block class="mt-0 mt-md-2" @click="updateLeaveItem(index)">
              <feather-icon icon="XIcon" class="mr-25" />
              <span>Save</span>
            </b-button>
            <b-button
              v-if="false"
              disabled
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="ml-1 mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
              <span>Delete</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>

      <b-button v-if="false" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeateAgain">
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>Add New</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code';
import { BButton, BCol, BForm, BFormDatepicker, BFormGroup, BFormInput, BRow } from 'bootstrap-vue';
import { dynamicHeightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    ToastificationContent,
    BFormDatepicker,
    BCardCode,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [dynamicHeightTransition],
  props: {
    profileData: { type: Object, required: true },
  },
  data() {
    return {};
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener('resize', this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },
  computed: {
    itemsLeave() {
      if (!this.profileData.history || !this.profileData.history.leaves) {
        return [];
      }

      return this.profileData.history.leaves;
    },
  },
  methods: {
    updateLeaveItem(index) {
      console.log(`> saving leave item ${index}`, this.itemsLeave[index]);
      return this.saveLeave();
    },
    saveLeave() {
      const payload = {
        leaves: this.itemsLeave,
      };

      store
        .dispatch('rosterStoreModule/updateProfileHistory', {
          profileId: this.profileData.id,
          historyData: payload,
        })
        .then((result) => {
          console.log('> saved leaves', result);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit profile',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
    initTrHeight() {
      this.trSetHeight('leaves', null);
      this.$nextTick(() => {
        this.trSetHeight('leaves', this.$refs.form.scrollHeight);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
