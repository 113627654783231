<template>
  <b-modal
    ref="modalUpsertTemplate"
    :visible="editProfileActive"
    @hidden="closeModal"
    size="lg"
    :busy="busy"
    :title="`Edit Roster Profile`"
    ok-title="Save Changes"
    centered
    @ok="saveChanges"
  >
    <b-row>
      <!-- Field: Attributes - Funding Source -->
      <b-col cols="6" lg="4" md="4">
        <b-form-group label="Funding Source" label-for="profile-attr-funding-source">
          <v-select
            id="profile-attr-funding-source"
            :value="profileEditData.attributes.fundingSource"
            :options="fundingSourceOptions"
            class="w-100"
            :clearable="false"
            :reduce="(val) => val.value"
            @input="(val) => (profileEditData.attributes.fundingSource = val)"
          />
        </b-form-group>
      </b-col>

      <!-- Field: Attributes - Payment Status -->
      <b-col v-if="false" cols="6" lg="4">
        <b-form-group label="Payment Status" label-for="profile-attr-payment-status">
          <v-select
            id="profile-attr-payment-status"
            :value="profileEditData.attributes.paymentStatus"
            :options="paymentStatusOptions"
            class="w-100"
            :clearable="false"
            :reduce="(val) => val.value"
            @input="(val) => (profileEditData.attributes.paymentStatus = val)"
          />
        </b-form-group>
      </b-col>

      <!-- Field: Attributes - Submitted to VA -->
      <b-col cols="6" lg="4" md="4">
        <b-form-group label="Submitted to VA" label-for="profile-attr-submitted-to-va">
          <v-select
            id="profile-attr-submitted-to-va"
            :value="profileEditData.attributes.submittedToVa"
            :options="submittedToVaOptions"
            class="w-100"
            :clearable="false"
            :reduce="(val) => val.value"
            @input="(val) => (profileEditData.attributes.submittedToVa = val)"
          />
        </b-form-group>
      </b-col>

      <!-- Field: coe uploaded -->
      <b-col cols="6" lg="4" md="4">
        <b-form-group label="Uploaded COE" label-for="profile-attr-u-coe">
          <v-select
            id="profile-attr-u-coe"
            :value="profileEditData.attributes.uploadedCoe"
            :options="uploadedCoeOptions"
            class="w-100"
            :clearable="false"
            :reduce="(val) => val.value"
            @input="(val) => (profileEditData.attributes.uploadedCoe = val)"
          />
        </b-form-group>
      </b-col>

      <!-- Field: transcript uploaded -->
      <b-col cols="6" lg="4" md="4">
        <b-form-group label="Uploaded Transcript" label-for="profile-attr-u-transcript">
          <v-select
            id="profile-attr-u-resume"
            :value="profileEditData.attributes.uploadedTranscript"
            :options="uploadedTranscriptOptions"
            class="w-100"
            :clearable="false"
            :reduce="(val) => val.value"
            @input="(val) => (profileEditData.attributes.uploadedTranscript = val)"
          />
        </b-form-group>
      </b-col>

      <!-- Field: resume uploaded -->
      <b-col cols="6" lg="4" md="4">
        <b-form-group label="Uploaded Resume" label-for="profile-attr-u-resume">
          <v-select
            id="profile-attr-u-resume"
            :value="profileEditData.attributes.uploadedResume"
            :options="uploadedResumeOptions"
            class="w-100"
            :clearable="false"
            :reduce="(val) => val.value"
            @input="(val) => (profileEditData.attributes.uploadedResume = val)"
          />
        </b-form-group>
      </b-col>

      <!-- Field: location -->
      <b-col cols="6" lg="4" md="4">
        <b-form-group label="Location" label-for="profile-attr-u-location">
          <v-select
            id="profile-attr-u-location"
            :value="profileEditData.attributes.location"
            :options="locationOptions"
            class="w-100"
            :clearable="false"
            :reduce="(val) => val.value"
            @input="(val) => (profileEditData.attributes.location = val)"
          />
        </b-form-group>
      </b-col>

      <!-- Field: Pathway -->
      <b-col cols="12" lg="6">
        <b-form-group label="Preferred Pathway" label-for="profile-pathway">
          <v-select
            id="profile-pathway"
            placeholder="Preferred Pathway"
            :value="profileEditData.pathway"
            :options="pathwaySuggestions"
            class="w-100"
            :reduce="(val) => val.value"
            :clearable="true"
            @search="searchForPathway"
            @input="(val) => (profileEditData.pathway = val)"
          />
        </b-form-group>
      </b-col>

      <!-- Field: Subject -->
      <b-col cols="12" lg="6">
        <b-form-group label="Preferred Subject" label-for="profile-subject">
          <v-select
            id="profile-subject"
            placeholder="Preferred Subject"
            :value="profileEditData.subject"
            :options="subjectSuggestions"
            class="w-100"
            :reduce="(val) => val.value"
            :clearable="true"
            @search="searchForSubject"
            @input="(val) => (profileEditData.subject = val)"
          />
        </b-form-group>
      </b-col>

      <!-- Field: Pathway Date -->
      <b-col cols="6" lg="4">
        <b-form-group label="Preferred Start Date" label-for="pathwayDateModal">
          <b-form-datepicker id="pathwayDateModal" v-model="profileEditData.pathwayDate" class="mb-1" />
        </b-form-group>
      </b-col>

      <!-- Field: Representative -->
      <b-col cols="12" lg="4">
        <b-form-group label="Representative" label-for="profile-representative">
          <vue-autosuggest
            id="profile-representative"
            ref="autocomplete"
            v-model="representativeQuery"
            :suggestions="representativeSuggestions"
            :input-props="representativeInputProps"
            :section-configs="representativeSectionConfigs"
            :render-suggestion="representativeRenderSuggestion"
            :get-suggestion-value="representativeGetSuggestionValue"
            @input="representativeFetchResults"
          />
        </b-form-group>
      </b-col>

      <!-- Field: Profile Status Reason -->
    </b-row>

    <b-row>
      <!-- Field: Status -->
      <b-col cols="6" lg="4" md="4">
        <b-form-group label="Status" label-for="profile-status">
          <v-select
            id="profile-status"
            :value="profileEditData.status"
            :options="statusOptions"
            class="w-100"
            :clearable="false"
            :reduce="(val) => val.value"
            @input="(val) => (profileEditData.status = val)"
          />
        </b-form-group>
      </b-col>

      <b-col v-if="shouldProvideReason" cols="6" lg="4" md="4">
        <b-form-group label="Status Reason" label-for="profile-status-reason">
          <b-form-input id="profile-status-reason" v-model="profileEditData.statusReason" class="w-100" />
        </b-form-group>
      </b-col>

      <!-- Field: Profile Status Update Date -->
      <b-col v-if="shouldProvideReason" cols="6" lg="4" md="4">
        <b-form-group label="Status Date" label-for="profile-status-date">
          <b-form-datepicker id="profile-status-date" v-model="profileEditData.statusDate" class="mb-1" />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BCol, BFormGroup, BButton, BFormInput, BModal, BRow, VBModal, BFormDatepicker } from 'bootstrap-vue';

import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ref, onUnmounted } from '@vue/composition-api/dist/vue-composition-api';
import useProfiles from '../useProfiles';
import rosterStoreModule from '../../../../../store/roster/rosterStoreModule';
import userStoreModule from '../../../../../store/user/userStoreModule';
import { VueAutosuggest } from 'vue-autosuggest';
import vSelect from 'vue-select';
import _ from 'lodash';

export default {
  name: 'ProfileEditModal',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BModal,
    BFormDatepicker,
    VueAutosuggest,
    vSelect,
  },
  computed: {
    shouldProvideReason() {
      return (
        this.profileEditData.statusReason ||
        ['withdrew_s1', 'withdrew_s2', 'withdrew_s3', 'terminated'].includes(this.profileEditData.status)
      );
    },

    showReassignSection() {
      return (
        this.profileEditData.status === 'leave_of_absence' ||
        this.profileEditData.reassignAt ||
        this.profileEditData.reassignReason ||
        this.profileEditData.reassignStart ||
        this.profileEditData.reassignEnd ||
        this.profileEditData.reassignFrom
      );
    },

    showTransferCreditSection() {
      return this.courseId && this.profileEditData.courses[this.courseId].toc.enabled; //this.profileEditData.toc && this.profileEditData.toc.enabled &&
    },
  },
  directives: { 'b-modal': VBModal },
  props: {
    editProfileActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    profileData: {
      type: Object,
      default: undefined,
    },
  },
  watch: {
    '$props.editProfileActive'(val) {
      this.initModal();
    },
    '$props.profileData'(val) {
      this.initModal();
    },
  },
  created() {
    this.initModal();
  },
  methods: {
    initModal() {
      if (this.$props.profileData) {
        // const { firstName, lastName, email, phoneNumber } = _.cloneDeep(this.$props.profileData);
        this.profileEditData = _.cloneDeep(this.$props.profileData);

        this.searchForPathway(``);
        this.searchForSubject(``);

        if (this.profileEditData.reassignFrom) {
          this.searchForSession(``);
        }

        this.representativeSelected = this.profileEditData.representative;
        if (this.representativeSelected) {
          this.representativeQuery = this.representativeSelected.name;
        }

        console.log(this.profileEditData);
      }
    },
    closeModal() {
      this.busy = false;
      this.$emit('update:edit-profile-active', false);
    },
    async doSelectRepresentative() {
      if (!this.representativeSelected) {
        return;
      }

      this.$store
        .dispatch('placementStoreModule/addStudentsToCohort', {
          cohortId: this.cohortData.id,
          studentIds: [this.representativeSelected.id],
        })
        .then((response) => {
          console.log(`updated course`, response);

          console.log(response.data);

          this.$emit('refresh');

          this.representativeQuery = undefined;
          this.representativeFetchResults();
        })
        .catch((e) => {
          console.error(`failed to update course`, e);
        });
    },
    representativeFetchResults() {
      const { query } = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        const usersPromise = this.$store.dispatch('userStoreModule/fetchUsers', {
          search: this.representativeQuery,
        });

        Promise.all([usersPromise]).then((values) => {
          this.representativeSuggestions = [];

          const users = values[0].data.results;

          users.length && this.representativeSuggestions.push({ name: 'users', data: users });
        });
      }, 250);
    },
    representativeRenderSuggestion(row) {
      return `${row.item.name}`;
    },
    representativeGetSuggestionValue(row) {
      if (row.item) {
        return this.representativeRenderSuggestion(row);
      }
      return `${row.name}`;
    },

    sessionRenderSuggestion(suggestion) {
      return suggestion.item.name;
    },
    sessionGetSuggestionValue(suggestion) {
      const { name, item } = suggestion;
      return item.name;
    },
    async searchForSession(search, loading) {
      const filter = { search, limit: 100, sortBy: 'createdAt:desc' };

      if (this.profileEditData.pathway) {
        filter.pathway = this.profileEditData.pathway;
      } else if (this.profileEditData.subject) {
        filter.subject = this.profileEditData.pathway;
      }

      const response = await this.$store.dispatch('rosterStoreModule/fetchPathwayCohortList', filter);
      const sessions = response.data.results;

      this.sessionSuggestions = sessions.map((p) => {
        let { id, name } = p;
        return { value: id, label: name };
      });
    },
    pathwayRenderSuggestion(suggestion) {
      return suggestion.item.name;
    },
    pathwayGetSuggestionValue(suggestion) {
      const { name, item } = suggestion;
      return item.name;
    },
    async searchForPathway(search, loading) {
      const response = await this.$store.dispatch('rosterStoreModule/fetchPathwayList', { search, limit: 100 });
      const pathways = response.data.results;

      this.pathwaySuggestions = pathways.map((p) => {
        let { id, name } = p;
        if (!name.includes('Pathway')) {
          name += ' Pathway';
        }
        return { value: id, label: name };
      });
    },
    subjectRenderSuggestion(suggestion) {
      return suggestion.item.name;
    },
    subjectGetSuggestionValue(suggestion) {
      const { name, item } = suggestion;
      return item.name;
    },
    async searchForSubject(search, loading) {
      const response = await this.$store.dispatch('rosterStoreModule/fetchCourseList', { search, limit: 100 });
      const subjects = response.data.results;

      this.subjectSuggestions = subjects.map((p) => {
        let { id, name } = p;
        return { value: id, label: name };
      });
    },
    saveChanges(event) {
      this.busy = true;

      event.preventDefault();

      const pl = { ...this.profileEditData };

      store
        .dispatch('rosterStoreModule/updateProfile', { profileData: pl })
        .then((response) => {
          // this.$emit('update:profile-data', response.data);

          this.busy = false;
          this.$emit('refetch-data');
          this.closeModal();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.busy = false;
          this.$emit('refetch-data');
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit profile',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  setup(props) {
    const profileEditData = ref({});
    const busy = ref(false);

    const representativeQuery = ref('');
    const representativeSuggestions = ref([]);
    const representativeSelected = ref({});
    const representativeSectionConfigs = {
      users: {
        limit: 10,
        label: 'Users',
        onSelected: (selected) => {
          representativeSelected.value = selected.item;
        },
      },
    };

    const representativeInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for users',
      class: 'form-control',
      name: 'ajax',
    };

    const {
      statusOptions,
      clearanceOptions,
      relocatableOptions,
      remoteOptions,
      fundingSourceOptions,
      paymentStatusOptions,
      submittedToVaOptions,
      uploadedCoeOptions,
      uploadedResumeOptions,
      uploadedTranscriptOptions,
      courseStatusOptions,
      examVoucherOptions,
      yesNoOptions,
      locationOptions,
    } = useProfiles();

    const sessionQuery = ref('');
    const sessionSuggestions = ref([]);
    const sessionSelected = ref(undefined);
    const sessionSectionConfigs = {
      sessions: {
        limit: 10,
        label: 'Sessions',
        onSelected: (selected) => {
          sessionSelected.value = selected.item;
          profileData.value.session = selected.item;
        },
      },
    };

    const sessionInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for sessions',
      class: 'form-control',
      name: 'ajax',
    };

    const pathwayQuery = ref('');
    const pathwaySuggestions = ref([]);
    const pathwaySelected = ref(undefined);
    const pathwaySectionConfigs = {
      pathways: {
        limit: 10,
        label: 'Pathways',
        onSelected: (selected) => {
          pathwaySelected.value = selected.item;
          profileData.value.pathway = selected.item;
        },
      },
    };

    const pathwayInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for pathways',
      class: 'form-control',
      name: 'ajax',
    };

    const subjectQuery = ref('');
    const subjectSuggestions = ref([]);
    const subjectSelected = ref(undefined);
    const subjectSectionConfigs = {
      subjects: {
        limit: 10,
        label: 'Subjects',
        onSelected: (selected) => {
          subjectSelected.value = selected.item;
          profileData.value.subject = selected.item;
        },
      },
    };

    const subjectInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for subjects',
      class: 'form-control',
      name: 'ajax',
    };

    return {
      profileEditData,
      busy,
      representativeQuery,
      representativeSuggestions,
      representativeSelected,
      representativeSectionConfigs,
      representativeInputProps,

      statusOptions,
      clearanceOptions,
      relocatableOptions,
      remoteOptions,
      fundingSourceOptions,
      paymentStatusOptions,
      submittedToVaOptions,
      uploadedCoeOptions,
      uploadedResumeOptions,
      uploadedTranscriptOptions,
      examVoucherOptions,
      yesNoOptions,
      courseStatusOptions,
      locationOptions,

      //
      sessionQuery,
      sessionSuggestions,
      sessionSectionConfigs,
      sessionInputProps,
      sessionSelected,
      pathwayQuery,
      pathwaySuggestions,
      pathwaySectionConfigs,
      pathwayInputProps,
      pathwaySelected,
      subjectQuery,
      subjectSuggestions,
      subjectSectionConfigs,
      subjectInputProps,
      subjectSelected,
    };
  },
};
</script>

<style lang="scss">
/* Add your custom styles here */
</style>
